import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import bg1 from './images/xdentity.jpg';
import bg2 from './images/fictivenation.jpg';
import $ from 'jquery';
import close from './images/close.svg';

var data_about_content = {
  "en_US": "Uchronia is a website-based artwork about Taiwanese identity and history. It was initiated and created by J. Chen, X.Y. Huang, in collaboration with Mark Liang and Iris Chun-Tzu Chang and many others. Uchronia, a portmanteau of utopia and chronos, refers to fictional time-periods of our world; reconstructed pasts, presents and speculative futures. The interactive website offers a new way of looking at Taiwan’s history from a uchronian perspective. \n\nTaiwan underwent multiple waves of colonization and, following World War II, a period of authoritarian regime. Its colonial history is marked by Spain, the Netherlands, the Chinese Ming government and Japan. Currently, inhabitants of Taiwan struggle to (re)discover or establish their own identity, finding themselves caught in the middle of a power game between Mainland China and the U.S.A.. This raises interrelated questions of identity, historical truth and the idea of Nation, as the official Taiwanese history had been written and rewritten by the imposing authorities throughout the island’s past. \n\nUchronia aims to reveal power structures underlying identity and provides artistic alternatives to create hybrid imaginative identities, evoking a rethinking of the methodology of history. ",
  "zh_TW": "「Uchronia 烏有史」藝術實驗計畫由藝術家X.Y. Huang和J.Chen共同創作，並與工程師Mark Liang、音樂設計Iris Chun-Tzu Chang 與多位朋友一同協作。\n\nUchronia由無何有之地（utopia）和時間（chronos）兩個字組成，指的是一段虛構的時間，包括：重建的過去、可能的現在、臆測的未來。\n\n我們希望透過「互動網站」的設計，讓觀眾重組影像與文字，創造出嶄新的「子虛烏有」（uchronian）敘事、重新看待臺灣歷史，並成為開創「另類歷史方法論」的起點。\n\n特別感謝叁式 Ultra Combos、Lightbox攝影圖書室、荷蘭攝影組織Paradox 創辦人Bas Vroege、臺灣歷史研究者林品君、西班牙藝術史研究者Beatriz Bloch在過程中的協助。"  
}

var data_credit = {
  "en_US":"Concept：J. Chen, X.Y. Huang\nInitiators：X.Y. Huang, Beatriz Bloch, J. Chen\nFirst Stage Content Design：J. Chen, X.Y. Huang, Pin Chun Lin, Sheng Wen Lo, Lin Tung, Yu Shang Huang, Beatriz Bloch\nLast Stage Content Design：J. Chen, X.Y. Huang\nVisual Design：J. Chen\nInteractive Design：J. Chen, X.Y. Huang\nProgramming：Mark Liang\nMusic Design : Iris Chun-Tzu Chang\nXdentity Workshop Proposal：X.Y. Huang, Fan Wu, , J. Chen. Assisted by Pin Chun Lin, Yu Shang Huang, Wei Lee\nXdentity Workshop Executive Staff：Fan Wu, Shin Chang, Pin Chun Lin, X.Y. Huang \nXdentity Workshop Film Crew：Fan Wu, I-Ju Lin, Shao Wen Liang\nXdentity Workshop Participants：Andrew Wong, Su Shin, Sunny Lyu, Kim Soonim, Anthony Chin, Ian Maxwell, Shun Peng Liu. The final stories were adapted by X.Y. Huang \nXdentity Workshop Site Cooperation：Thinkers' Theatre, Lightbox Photo Library\nXdentity Workshop Promotion Cooperation：TransAsia Sisters Assosiation, Brilliant Time bookstore, TCAC (Taipei Contemporary Art Center) \nFictive Nation tags and images collections：J. Chen, X.Y. Huang, Pin Chun Lin, Yu Shang Huang, Shin Chang, Rok Kranjc, Ching Chiu, Fanny Chao, Gašper Tavželj, Hsiao Ya-Ting, Cara, Yen-Chen Chu, Chen Yueh-Yin\nTaiwanese History Consultant：Pin Chun Lin\nEnglish Translators：X.Y. Huang, Shin Chang\nEnglish Editor：Rok Kranjc\nSpecial Thanks：Bas Vroege, FramerFramed, Lightbox Photo Library, F Studio ",
  "zh_TW":"作品論述與概念：X.Y. Huang、J. Chen\n發起人：X.Y. Huang、Beatriz Bloch、J. Chen\n前期內容設計：J. Chen、X.Y. Huang、林品君、羅晟文、林彤、黃俞瑄、Beatriz Bloch\n後期內容設計：J. Chen、 X.Y. Huang\n網站視覺設計：J. Chen\n網站互動設計：J. Chen、 X.Y. Huang\n網站程式設計： 梁祐文\n音樂設計： Iris Chun-Tzu Chang\nXdentity工作坊企劃：X.Y. Huang、吳璠。J. Chen、林品君、黃俞瑄、李薇協助。\nXdentity 工作坊執行與影音紀錄：吳璠、林誼如、梁紹文、張新、林品君、X.Y. Huang\nXdentity 工作坊影片剪輯與後製：吳璠、X.Y. Huang\nXdentity 說故事者：原始故事參與朋友：Andrew Wong、蘇欣、呂曼華、Kim Soonim、Anthony Chin、Ian Maxwell、劉順鵬，後經X.Y. Huang改編\nXdentity 場地協力單位：大稻埕思劇場、Lightbox 攝影圖書室\nXdentity 宣傳協力單位：南洋臺灣姊妹會、燦爛時光：東南亞主題書店、TCAC 臺北當代藝術中心\nFictive Nation 圖片彙整：J. Chen、X.Y. Huang、林品君、黃俞瑄、張新、Rok Kranjc 、邱敬、趙芳儀、Gašper Tavželj、蕭雅庭、朱晏禎、Cara、陳悅音\n臺灣歷史顧問：林品君\n英文翻譯：X.Y. Huang、張新\n英文編輯：Rok Kranjc\n特別感謝：Bas Vroege、FramerFramed、叁式Ultra Combos、F Studio \n"
}

var data1 = {
  "title": "Uchronia 烏有史",
  "xdentity": "Ｘ認同",
  "fictivenation": "虛構的國度",
  "about": "關於計畫"
}

var data2 = {
  "title": "Uchronia",
  "xdentity": "Xdentity",
  "fictivenation": "Fictive\nNation",
  "about": "About"
}

var data = {
  "en_US": data2,
  "zh_TW": data1
}

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lang: window.location.hash? window.location.hash.replace('#',''):"en_US",
      data: window.location.hash? data[window.location.hash.replace('#','')]:data["en_US"]
    }
  }
  componentDidMount(){
    window.location.hash ? $('.lang span'+window.location.hash).addClass('activeLang') : $('#en_US').addClass('activeLang');
  }
  changeLang() {
    var a = "";
    if(this.state.lang == "en_US") {
      a = "zh_TW";
      $('.lang span').text("EN")
    }
    else {
      a = "en_US";
      $('.lang span').text("中")
    }
    
    this.setState({
      lang: a,
      data: data[a]
    });

    //this.props.changeLang(a);
    
    // $('.activeLang').removeClass('activeLang');
    // $('#'+a).addClass('activeLang');
    window.location.hash = a;
  }

  render() {
    var data = this.state.data;
    var container = {
      height: "calc(100vh - 82px)"
    }
    var bg1style = {
      backgroundImage: "url("+bg1+")",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat"
    }
    var bg2style = {
      backgroundImage: "url("+bg2+")",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat"
    }

    return (
      <section id="HOME" className="h-100 flex items-center justify-between flex-column">
        <nav>
          <h1 className="tc fw1 f35 pa0 ma0 ttu cp">
            <span className="white">{data["title"]}</span>
          </h1>
        
          <div className="lang white absolute">
            <span className="cp" onClick={() => this.changeLang()}>{this.state.lang === "en_US" ? "中":"EN"}</span>
          </div>
        </nav>
        <div className="container w-100" style={container}>
          <div className="w-100 h-100">
            <div className="cf h-100">
              <Link to={"/Xdentity#"+this.state.lang}>
                <div className="fl w-100 w-50-ns h-100">
                  <div className="pv4 tc h-100 flex items-center justify-center" style={bg1style}>
                    <h3>{data["xdentity"]}</h3>
                  </div>
                </div>
              </Link>
              <Link to={"/FictiveNation#"+this.state.lang}>
                <div className="fl w-100 w-50-ns h-100">
                  <div className="pv4 tc h-100 flex items-center justify-center" style={bg2style}>
                    <h3>{data["fictivenation"]}</h3>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
        <Modal lang={this.state.lang}/>
      </section>
    );
  }
}

export default Home;


class Modal extends Component {
  constructor(props) {
      super(props);
  }
  componentDidMount() {
    $('.closeModal').click(function(){
      $('.modal').removeClass('show');
    })
    $('nav h1').click(function(){
      $('.modal').addClass('show');
    })
  }

  render() {  
  return(
    <div className="modal ph4 pv3">
      <div className="closeModal cp"><img src={close} width="16px" height="16px"/></div>
      <p className="pn f6 fw2 lh-copy pre-wrap">{data_about_content[this.props.lang]}</p>
      <hr className="pn mv5 mw600"/>
      <p className="pn f6 fw2 lh-copy pre-wrap pb5">{data_credit[this.props.lang]}</p>
    </div>
  )
  }
}
