import React, { Component } from 'react';
import { BrowserRouter, Route, Link, Redirect, withRouter } from 'react-router-dom';
import Cookies from 'universal-cookie';
import './App.css';
import Home from './Home.js';
import Page1 from './Page1.js';
import Page2 from './Page2.js';
import About from './About.js';
import $ from 'jquery';

const cookies = new Cookies();

const fakeAuthCentralState = {
  isAuthenticated: cookies.get('login') ? true : false,
  authenticate(callback) {
    this.isAuthenticated = true;
    setTimeout(callback, 300);
  },
  signout(callback) {
    this.isAuthenticated = false;
    setTimeout(callback, 300);
  }
};

const ProtectedRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => (
    fakeAuthCentralState.isAuthenticated === true
      ? <Component {...props}/>
      : <Redirect to={{
          pathname: '/',
          state: { from: props.location }
        }} />
  )} />
);

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
      check: false,
      checkValue: [1,1,1,1,1,1],
      open: [true,true,true,true,true,true],
      redirectToReferrer: false,
    	lang: "en_US",
      mobile: $(window).width() <= 768 ? true : false
    }
    this.changeLang = this.changeLang.bind(this);
    this.checkMobile = this.checkMobile.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeCheck = this.handleChangeCheck.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  componentDidMount() {
    if(cookies.get('login')) $('#password').addClass('o-0 pn');
    cookies.set('source', "111111" , { path: '/', sameSite: 'none', secure: true});
    var $t = this;
    window.addEventListener('resize', $t.checkMobile, false);
    $('input[type=checkbox], input[type=checkbox] + label').hide();
  }
  componentWillUnmount(){
    var $t = this;
    window.removeEventListener('resize', $t.checkMobile, false);
  }
  checkMobile() {
    var $t = this;
    if($(window).width() <= 768) $t.setState({mobile:true});
    else $t.setState({mobile:false});
  }
  changeLang(a) {
    console.log(a);
    this.setState({lang: a});
    this.forceUpdate();
  }

  handleChange(event) {    
    this.setState({value: event.target.value});  
  }

  handleChangeCheck(event) {    
    var openarray = this.state.open;
    var array = this.state.checkValue;
    var id = event.target.id.split('source')[1];
    array[id - 1] = event.target.checked ? 1 : 0
    openarray[id - 1] = event.target.checked ? true : false;
    this.setState({checkValue: array, open: openarray});  
  }

  handleSubmit(event) {
    if(this.state.value === "UchroniaPWD") {
      this.login();
      $('#password').addClass('o-0 pn');
    } else if(this.state.value === "FictiveNationPWD") {
      if(!this.state.check) {
        this.setState({check:true});
        $('input[type=checkbox], input[type=checkbox] + label').show();
      }
      else {
        var text = this.state.checkValue.join('');
        cookies.set('source', text , { path: '/', sameSite: 'none', secure: true});
        this.login();
        $('#password').addClass('o-0 pn');
      }
    }
    event.preventDefault();
  }

  login = () => {
    cookies.set('login', true , { path: '/', sameSite: 'none', secure: true, maxAge: 3600000});
    fakeAuthCentralState.authenticate(() => {
      this.setState(() => ({
        redirectToReferrer: true
      }));
    });
  }

  render() {

    return (
      <BrowserRouter basename="/">
        <div id="mobile" className={this.state.mobile ? "flex ph4":"dn"}>This website doesn't support mobile view.</div>
        <div id="password" className="absolute pa4 w-100 h-100 bg-black white z100 flex items-center justify-center">
           <form onSubmit={this.handleSubmit}>
            <label for="pwd" className="mr2">Password:</label>
            <input type="text" id="pwd" name="pwd" value={this.state.value} onChange={this.handleChange} /><br/><br/>
            <input type="submit" value="Submit"/>
            <br/><br/>
            <input type="checkbox" id="source1" name="deVolksktrant" value="deVolksktrant" checked={this.state.open[0]} onChange={this.handleChangeCheck}/>
            <label className="mr2" for="deVolksktrant">deVolksktrant</label>
            <input type="checkbox" id="source2" name="Yomirui%20Shimbun%20(The%20Japan%20News)" value="Yomirui%20Shimbun%20(The%20Japan%20News)" checked={this.state.open[1]} onChange={this.handleChangeCheck}/>
            <label className="mr2" for="Yomirui%20Shimbun%20(The%20Japan%20News)">Yomirui Shimbun (The Japan News)</label>
            <input type="checkbox" id="source3" name="Lifemagazine" value="Lifemagazine" checked={this.state.open[2]} onChange={this.handleChangeCheck}/>
            <label className="mr2" for="Lifemagazine">Lifemagazine</label>
            <input type="checkbox" id="source4" name="People's%20Daily%2C%20Yule%2C%20XinHuaNet" value="People's%20Daily%2C%20Yule%2C%20XinHuaNet" checked={this.state.open[3]} onChange={this.handleChangeCheck}/>
            <label className="mr2" for="People's%20Daily%2C%20Yule%2C%20XinHuaNet">People's Daily, Yule, XinHuaNet</label>
            <input type="checkbox" id="source5" name="20%20Minutos%2C%20EL%20PA%C3%8DS" value="20%20Minutos%2C%20EL%20PA%C3%8DS" checked={this.state.open[4]} onChange={this.handleChangeCheck}/>
            <label className="mr2" for="20%20Minutos%2C%20EL%20PA%C3%8DS">20 Minutos, EL PAÍS</label>
            <input type="checkbox" id="source6" name="TheJakartaPost" value="TheJakartaPost" checked={this.state.open[5]} onChange={this.handleChangeCheck}/>
            <label className="mr2 mb4" for="TheJakartaPost">TheJakartaPost</label>
          </form> 
        </div>
        <Route
		  exact path='/'
		  render={(props) => <Home {...props} lang={this.state.lang} changeLang={this.changeLang}/>}
		/>
        <ProtectedRoute
		  path='/FictiveNation'
		  component={Page1}
		/>
        <ProtectedRoute
		  path='/Xdentity'
		  component={Page2}
		/>
        <Route
      path='/About'
      render={(props) => <About {...props} lang={this.state.lang} />}
    />
      </BrowserRouter>
    );
  }
}

export default App;