import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import $ from 'jquery';
import Typist from 'react-typist';

import f1 from './images/portrait_1_empty.png';
import f2 from './images/portrait_2_empty.png';
import f3 from './images/portrait_3_empty.png';
import f4 from './images/portrait_4_empty.png';
import f5 from './images/portrait_5_empty.png';
import f6 from './images/portrait_6_empty.png';

import e1 from './images/portrait_1_eyes.png';
import e2 from './images/portrait_2_eyes.png';
import e3 from './images/portrait_3_eyes.png';
import e4 from './images/portrait_4_eyes.png';

import n1 from './images/portrait_3_nose.png';
import n2 from './images/portrait_4_nose.png';
import n3 from './images/portrait_5_nose.png';
import n4 from './images/portrait_6_nose.png';

import m1 from './images/portrait_5_mouth.png';
import m2 from './images/portrait_6_mouth.png';
import m3 from './images/portrait_1_mouth.png';
import m4 from './images/portrait_2_mouth.png';

import music from './images/Xdentity-Voice-3minutes.mp3';
import close from './images/close.svg';

var word_content = [];

var data1 = {
  "home": "回到首頁",
  "button": ["創造身分", "重新創造身分"],
  "face": [f1, f2, f3, f4, f5, f6],
  "eyes":  [e1, e2, e3, e4],
  "nose": [n1, n2, n3, n4],
  "mouth":[m1, m2, m3, m4],
  "tooltips": [
    ["露出眼睛", "改換眼睛"],
    ["露出鼻子", "改換鼻子"],
    ["露出嘴巴", "改換嘴巴"]
  ],
  "paragraphs1": [
    [
      "這是關於我自己的故事。",
      "我們全家從香港搬到加拿大。",
      "我感覺自己是局外人。",
      "我在加拿大的法語區的英文區，變成局外的人的局外人。",
      "跑到台灣學中文，但發現自己還是局外人。",
      "我終於發現沒什麼好在當局內人的，因為局外人就是我的身分認同。"
    ],
    [
      "這是關於一個小馬鈴薯的故事。",
      "我們從超市的馬鈴薯區搬到番茄區。",
      "小馬鈴薯感覺自己格格不入。",
      "他在味道甜甜紅紅的番茄區，變成又大又胖的外來種。",
      "滾回馬鈴薯區，但發現自己跟其他馬鈴薯長得不太一樣，因為農夫已經停止量產我的品種。",
      "他突然領悟馬鈴薯才是我的家，因為至少我們都還是馬開頭的。"
    ],
    [
      "這是關於一個女孩的故事。",
      "她的媽媽從台灣搬到荷蘭。",
      "小女孩感覺自己在兩邊都不融入。",
      "她在高大的荷蘭人之中，變成有嬌小迷魅的東方人。",
      "飛回家鄉，但發現自己對台灣的記憶充滿幻覺，因為從來沒真正在這裡生活過。",
      "她突然懂了我不是西方人也不是東方人，因為我就是永遠介在這兩個文化之間。"
    ],
    [
      "這是關於一隻蝌蚪的故事。",
      "他們舉家從湖水裡搬到池塘邊。",
      "蝌蚪感覺自己一直被歧視。",
      "他在水裡不像魚游得又快又好，總是被叫「小」蝌蚪。",
      "搬到陸地，但發現自己竟然已經完全不會游泳了，因為他已蛻變成一隻跳來跳去的青蛙。",
      "他突然體悟，至少他現在不是井底之蛙，因為他可以看到陸地的風景，這是大魚看不到的。"
    ],
  ],
  "paragraphs2": [
    [
      "我從小夢想去維也納學鋼琴。",
      "前往夢寐以求的歐洲，",
      "畢業後開始當鋼琴家教，",
      "但是因為排外政策的關係，離開奧地利。",
      "最後只好搬到文化比較包容的荷蘭定居，",
      "開啟一家小小的壽司店，名為東京廚房，幻想自己在日本的家。"
    ],
    [
      "我從小最討厭回我媽媽的故鄉吃榴蓮。",
      "逃開我從小成長的地方，",
      "二十歲後成為法國藍帶廚藝學校的學生，",
      "但是因為媽媽自殺的關係，很難繼續過著逃家生活。",
      "最後只好回到故鄉尋找錯過的時光，",
      "開啟一個小小的當舖，名為榴蓮忘返，幻想我的媽媽還在我身邊。"
    ],
    [
      "孫甯從小幻想能玩美國的高級玩具。",
      "抵達傳說中無比自由的美國，",
      "結婚後開始當家庭主婦，",
      "但是因為跟美籍先生離婚的關係，很難繼續待在美國。",
      "最後只好回到老家韓國鄉下，",
      "開啟一個小小的英文補習班，名為美國夢，幻想自己還過得不錯。"
    ],
    [
      "深海魚從小希望自己能看到世界的顏色。",
      "前往燈紅酒綠的海洋表層透光帶，",
      "抵達後開始體驗前所未有的感官刺激，",
      "但是因為海洋表層壓力太小，身體腫脹，很難繼續在表層生活。",
      "最後只好回到一片漆黑的海底，",
      "開啟一個小小的樂團，名為海平面，幻想自己仍在年輕的夢裡。"
    ],
  ],
  "paragraphs3": [
    [
      "鯨魚殖民地球之際，",
      "整個島嶼被迫沉到海底，成為水中國度，",
      "所有陸地生物被迫裝上海底呼吸器與學習用聽力與聲波和海中生物溝通，",
      "但是人的耳朵構造有限，許多音頻無法聽到，",
      "因此，人類被視為海洋世界最低等的公民。",
      "每年有成千上萬的人想進各種辦法要回到陸地上，",
      "統治者派殺人鯨在海與路的界線，現場處決偷渡者。",
      "如果從空中看地球，",
      "每天都可以看到海陸邊界沾染著人類的鮮血，只是在海底下的人都看不到。"
    ],
    [
      "台灣殖民全球之際，",
      "全世界被迫成立工業所，提供資源給台灣，所有在台灣島上的人不需要工作，每年還有基本收入可以拿，",
      "所有人被迫學習台灣的語言和台灣文化，",
      "但是有些人沒有足夠的錢學習臺灣文化，",
      "因此，無法學會台灣文化的人，被視為二等公民。",
      "每天有成千上萬的駭客跟起義者，想盡各種辦法想要打倒台灣島，",
      "統治者在其上空乃至海底，與資訊防護網，都有多層縝密，無法被戳破的防線。",
      "如果從宇宙看資訊網，",
      "每天都有人在網路雲上發起革命而被捕訊息，只是他們彼此都不知道真相。"
    ],
    [
      "精神病統治世界之際，",
      "全世界本來在社會工作的人都被關進監獄，在裡面進行改造使用各種藥物以理解精神病的世界，",
      "所有人被迫學習解離和理解感知經驗以外的另一個世界，",
      "但有許多人用了再多的藥物還是無法解離，",
      "因此，無法解離的人，被視為最無知的一群人。",
      "每天有成千上萬的人想要離開監獄，打倒試圖控制他們的統治者，",
      "統治者直接在這些逃獄人的腦內植入晶片，改變這些人的意識。",
      "如果從外太空遙望，",
      "每天可以看到不管是被裝晶片的人，還是精神病的統治者，他們的意識其實都是被位屬於另一個星球中，一個無極大的桶中腦所控制。"
    ],
    [
      "手機統治世界之際，",
      "全世界的人都被關進電力發電所，在裡面進行生產與研發永續電力的工作，使手機永遠不會關機，",
      "所有人都被手機操控，沒有自己決定行動的能力，",
      "但許多沒有辦法讓自己有任何可以被手機利用的價值，",
      "因此，只要跟手機無關的生物，都被視為低度智商的物種。",
      "每天有成千上萬的生物想要摧毀發電機，並使手機永久關機，",
      "手機已發明智慧系統，讓人類無法輕易將手機關機。",
      "如果從手機的角度看人類，",
      "每天可以發現有不少犯人想要修改手機的智慧系統，但他們都不知道自己的一舉一動早已被寫在區塊鏈上，他們將永世無法出獄，也沒有任何辯解的空間，只是沒有人知道自己已經全面被監控。"
    ],
  ]
}

var data2 = {
  "home": "Home",
  "button": ["Make an Identity", "Make a New Identity"],
  "face": [f1, f2, f3, f4, f5, f6],
  "eyes":  [e1, e2, e3, e4],
  "nose": [n1, n2, n3, n4],
  "mouth":[m1, m2, m3, m4],
  "tooltips": [
    ["reveal eyes", "switch eyes"],
    ["reveal nose", "switch nose"],
    ["reveal mouth", "switch mouth"]
  ],
  "paragraphs1": [
    [
      "This is a story about me, Allan. ",
      "My whole family moved from Hong Kong to Canada when I was little. ",
      "I felt like an outsider there. ",
      "I became known as “Alien” among my schoolmates for living in an English-speaking part of French Canada. ",
      "I moved back to Taiwan to learn Chinese but I figured out that I am an outsider there as well. ",
      "I realized there is no sense in trying to be an insider because being an outsider is a core part of my identity. "
    ],
    [
      "This is a story about Little p. ",
      "His whole family moved from the potato to the tomato section of the supermarket. ",
      "Little p felt like he did not fit in. ",
      "He became known as a giant and fat exoctic species amongst the sweet, reddish small tomatoes. ",
      "Rolling back to the potatoes section one day, he discovered that he does not look exactly like the other potatoes, as the farmer had stopped producing his kind. ",
      "He realized that the potatoes were still where his home was because at least all their last names started with a “P”. "
    ],
    [
      "This is a story about a little girl. ",
      "Her mom moved from Taiwan to the Netherlands. ",
      "The girl felt out of place in both countries. ",
      "She became known as a petite and charming “oriental beauty” by the tall Dutch people. ",
      "Flying back to her homeland, she discovered that her memories of Taiwan were mere fantasy, as she never really lived here. ",
      "She suddenly understood that she was not a Westerner nor an oriental girl, she was always something neither and in-between. "
    ],
    [
      "This is a story about a tadpole. ",
      "His whole family moved from the lake to the land around the pond. ",
      "The tadpole had always felt discriminated against by all the animals in the pond. ",
      "He became known as “Slowpoke” among the smug, fast-swimming fish of the pond. ",
      "Moving to the land around the pond, he realized he could no longer swim because he had transformed into a leaping frog. ",
      "He suddenly realized that he was no longer a “frog in a well” with a limited view, he could see the whole land. A view that the smug fish can never see. "
    ],
  ],
  "paragraphs2": [
    [
      "I’ve dreamt of studying piano in Vienna ever since I was little. ",
      "Went to dreamy Europe. ",
      "After I graduated, I became a piano tutor. ",
      "However, due to anti-immigrant policy, I was forced to leave Austria. ",
      "It turned out that I could only move to the Netherlands, where they had a more lax migration policy. ",
      "Opened up a small sushi restaurant called Tokyo Kitchen, dreaming I was back in my hometown in Japan. "
    ],
    [
      "I’ve hated going back to my mom’s hometown to eat durian ever since I was little. ",
      "Escaped from the place where I grew up. ",
      "As I passed my twenties, I became a chef at Le Cordon Bleu. ",
      "However, after my mom’s suicide, I could hardly live a life by continuing to escape home. ",
      "It turned out that I could go back to my hometown only in search of lost time. ",
      "Opened up a small pawnshop called Lingering Durian, imagining that my mom was still with me. "
    ],
    [
      "Soonim imagined she would play with high-end toys from the USA ever since she was little. ",
      "Arrived in legendary America, land of the free. ",
      "After she got married, she became a housewife. ",
      "However, due to her divorce from her American husband, she couldn’t really stay in America. ",
      "It turned out that she could only go back to her homeland, Korea. ",
      "Opened up a small English cram school called American Dream, imagining she’s living an “okay” life. "
    ],
    [
      "Ever since she was little, the deep sea fish wished she could see all the colors of the world. ",
      "Went on a journey to the colorful sunlit waters near the ocean surface. ",
      "After she arrived, she experienced an explosion of sensations, the likes of which she had never experienced before. ",
      "However, due to the lower pressure at the surface, the fish risked her body blowing up, so she could hardly settle there. ",
      "It turned out that she could only descend back into the darkest crevices of the deep sea. ",
      "Started a small band called Surface, fantasizing she is living out her childhood dream. "
    ],
  ],
  "paragraphs3": [
    [
      "When the whales colonized the Earth, ",
      "the whole island was made to sink into the sea, becoming an underwater nation. ",
      "All the terrestrial animals were forced to put on respirators and learn to communicate with sea creatures using water sound waves. ",
      "However, due to limitations of the human ear, there is a whole range of audio frequencies that the humans couldn’t hear. ",
      "Therefore, human beings that couldn’t communicate with aquatic life became regarded as the lowest class of citizens. ",
      "Every year, there were thousands of surviving humans yearning to return to solid land. ",
      "The ruler of the sea creatures sent killer whales to the border between sea and land to  conduct on-site executions of attempted fugitives. ",
      "If one looked at the Earth from a bird’s eye view,",
      "every day one could see the border between the sea and land, constantly redrawn in human blood. Blood that those deep beneath the surface could not see. "
    ],
    [
      "When the Taiwanese colonized the globe, ",
      "the whole world was forced to establish extractive industries in order to supply resources to Taiwan - the Taiwanese did not have to work a day in their lives, enjoying an annual basic income. ",
      "Everyone was forced to learn to speak Taiwanese and learn Taiwan’s culture by heart. ",
      "However, there were some people who didn’t earn enough resources to learn Taiwanese language and culture. ",
      "Therefore, those who couldn’t afford to learn Taiwanese language and culture became second-rate citizens. ",
      "Every day, there were lots of attempts by activists and hackers to overthrow the Taiwanese hegemony. ",
      "The ruler ordered the construction of an impenetrable and multilayered line of informational defense, reaching all the way from the sky to the depths of sea trenches. ",
      "If one looked at the informational network from the perspective of the universe,",
      "every day one could see people who initiate cloud revolutions on the internet being arrested. But they did not know the truth. "
    ],
    [
      "When the insane ruled the world, ",
      "all the laborers of the world were sent into camps, where they were transformed through drugs and medicines into subjects that could fathom the world of the insane. ",
      "Everyone was forced to develop a dissociative identity disorder and understand realities outside their perceptual world. ",
      "However, there remained people who, even with extensive use of drugs and medicines, could not experience dissociative identity. ",
      "Therefore, those who couldn’t dissociate from their identity were considered as the most ignorant people of all. ",
      "Every day, there were thousands of people trying to escape the camps, struggling against their rulers who attempted to control every aspect of their being. ",
      "The ruler began inserting computer chips into the brains of the escapees in order to change their consciousness. ",
      "If one looked into the distance from outer space,",
      "every day one would be reminded that in reality, both the brain-chipped people and the insane ruler had their consciousnesses controlled by an extremely huge brain in a vat. "
    ],
    [
      "When cell phones ruled the world, ",
      "all the creatures of the world were imprisoned in power stations, forced to produce and conduct research on sustainable energy that could keep phones turned on forever. ",
      "Everyone fell under the cell phones’ control and no longer had any freedom to determine their actions. ",
      "However, there were some creatures which could not work and were deemed essentially irrelevant to the cell phones. ",
      "Therefore, those creatures that couldn’t work for the cell phones were considered by the cell phones as the “sub-zero intelligences”. ",
      "Every day, there were thousands of creatures who attempted to destroy the power stations and force the cell phones to shut down permanently. ",
      "The cell phones emperor had already invented a smart automated system which made shut-down by means of external force practically impossible. ",
      "If one saw the creatures from the perspective of the cell phones,",
      "every day one could see criminals who sought to secretly slip away bits of control from the cell phones and to reprogram their operating system. Little did the criminals know they were being constantly surveillanced and had all their actions recorded on the blockchain. They were all imprisoned for life without parole. "
    ],
  ]
}

var data = {
  "en_US": data2,
  "zh_TW": data1
}

var data_home = {
  "#en_US": "Uchronia",
  "#zh_TW": "Uchronia 烏有史"  
}
var data_title = {
  "#en_US": "Xdentity",
  "#zh_TW": "Ｘ認同"
}

var data_about = {
  "#en_US": "About",
  "#zh_TW": "關於"  
}

var data_about_content = {
  "#en_US": "In the section Xdentity, by shifting from “I” to an “X”, we deconstruct the idea of “I”-centered forms of identity. The focus on “I” entails the dangers of exclusivity which can lead to specific formations of national identity and divisions based on ethnicity and blood kinship, which are then taken as excuses for systemic violence, radical nationalism or racism. In order to  deconstruct and reframe these forms of identity, we aim to provide an alternative mode of identity formation. The user chooses stories belonging to three distinct categorizations: personal identity, cultural/national identity and identity that goes beyond social or even strictly human domains. \n\nThe stories were made during a storytelling workshop with participants with diasporic backgrounds. The stories under the same categories use the same story structure but contain different content, creating the appearance as if the same story repeats itself in different variations. In this way real-life stories and fictive stories become composites, creating a space for mutually understanding of the past and creating new futures narratives. \n\nBy enabling the mixing of real/fictive elements, and the three levels of identity, the website allows users to create a hybrid-identity for themselves. We hope the website will serve as a starting point to create a new form of identity, Xdentity - one that drifts between similarity and difference, placed in-between human, non-human, and beyond the borders of our thought.",
  "#zh_TW": "X認同 (Xdentity) 將英文的 Identity改成 Xdentity，以此象徵性地解構以「我」（Ｉ）為主體的身分認同。身分認同背後隱含的政治問題，其中一部份是身分認同伴隨而來的「排他性」，像是國家主義、民族主義，強調以血緣關係而形成的族群，以此口號為基礎帶來的極端國家主義跟種族主義造成系統性的暴力，也引發我們思考，是否有可能建立一種新的認同，甚至是另一種形塑認同的社會過程。\n\nXdentity透過創造「相似」與「差異」互相交織的敘事過程，形成關於認同的故事。在網站介面上，每位使用者可以從三個類型的故事中，分別挑出自己認同的故事，這三個類型包括：「個人游離的身分認同」、「文化/國家認同到超越社會性的身分認同」，甚至包含「非人類」的身分認同。這些故事的原型來自我們所舉辦的「我的身世你來說工作坊」，工作坊邀請擁有離散經驗的朋友分享自己身分認同的故事，並請其他朋友將故事改編，以同樣的結構填上不一樣的內容，因此，每一個人的故事原型都有不同的版本，使虛構與真實交織在一起。\n\n透過融合虛實元素的以及不同層次身分認同故事，我們企圖使說故事的人更加了解彼此的過去，並共同創造出關於未來的新敘事，以及屬於自己的混雜身分認同(hybrid identity)，形塑一個介於「相似」與「差異」之間、「人」與「非人」之間的身分認同，並逐漸超越我們思想的邊界。"  
}

class Page2 extends Component {
  constructor(props) {
    super(props);
    var s = Math.floor((Math.random() * 4) + 1);
    var e = Math.floor((Math.random() * 4) + 1);
    var n = Math.floor((Math.random() * 4) + 1);
    var m = Math.floor((Math.random() * 4) + 1);
    var f = Math.floor((Math.random() * 6) + 1);
    console.log(n);
    this.state = {
      stage: 1,
      face: f,
      start: s,
      eyes: e,
      nose: n,
      mouth: m,
      result: false,
      lang: window.location.hash? window.location.hash.replace('#',''):"en_US",
      data: window.location.hash? data[window.location.hash.replace('#','')]:data["en_US"],
      change: false
    }
    this.showResults = this.showResults.bind(this);
    this.showFaces = this.showFaces.bind(this);
  }

  changeLang() {
    var a = "";
    if(this.state.lang == "en_US") {
      a = "zh_TW";
      $('.lang span').text("EN")
    }
    else {
      a = "en_US";
      $('.lang span').text("中")
    }
    
    this.setState({
      lang: a,
      data: data[a]
    });

    //this.props.changeLang(a);
    
    // $('.activeLang').removeClass('activeLang');
    // $('#'+a).addClass('activeLang');
    window.location.hash = a;
  }

  changeContent(a) {
    var data = this.state.data;
    word_content = [];
    var we1 = data["paragraphs1"][this.state.eyes-1][0].concat(data["paragraphs1"][this.state.eyes-1][1]);
    var we2 = data["paragraphs1"][this.state.eyes-1][2].concat(data["paragraphs1"][this.state.eyes-1][3]);
    var wn1 = data["paragraphs2"][this.state.nose-1][0].concat(data["paragraphs2"][this.state.nose-1][1]);
    var wn2 = data["paragraphs2"][this.state.nose-1][2].concat(data["paragraphs2"][this.state.nose-1][3]);
    var wm1 = data["paragraphs3"][this.state.mouth-1][0].concat(data["paragraphs3"][this.state.mouth-1][1]);
    var wm2 = data["paragraphs3"][this.state.mouth-1][2].concat(data["paragraphs3"][this.state.mouth-1][3]);
    var st1 = (a === 0) ? (<div className="mv4" id="word-1"><span>{we1}</span><Typist avgTypingDelay={35} onTypingDone={function(){setTimeout(function(){$("#word-2").removeClass('hide'); $('body').removeClass('loading');},400)}} cursor={{hideWhenDone: true, hideWhenDoneDelay: 400}}>{we2}</Typist></div>) : (<div className="mv4" id="word-1">{we1.concat(we2)}</div>);
    var st2 = (a === 1) ? (<div className="mv4" id="word-2"><span>{wn1}</span><Typist avgTypingDelay={35} onTypingDone={function(){setTimeout(function(){$("#word-3").removeClass('hide'); $('body').removeClass('loading');},400)}} cursor={{hideWhenDone: true, hideWhenDoneDelay: 400}}>{wn2}</Typist></div>) : (<div className="mv4" id="word-2">{wn1.concat(wn2)}</div>);
    var st3 = (a === 2) ? (<div className="mv4" id="word-3"><span>{wm1}</span><Typist avgTypingDelay={35} onTypingDone={function(){setTimeout(function(){$('#result').removeClass('dn'); $('body').removeClass('loading');},400)}} cursor={{hideWhenDone: true, hideWhenDoneDelay: 400}}>{wm2}</Typist></div>) : (<div className="mv4" id="word-3">{wm1.concat(wm2)}</div>);
    word_content.push(
      <div className="pa5 xgreen lh-copy h-100 overflow-scroll">
        <div className="mv4" id="word-1">{we1}</div>
      </div>
    );
    word_content.push(
      <div className="pa5 xgreen lh-copy h-100 overflow-scroll">
        {st1}
        <div className="mv4" id="word-2">{wn1}</div>
      </div>
    );
    word_content.push(
      <div className="pa5 xgreen lh-copy h-100 overflow-scroll">
        {st1}
        {st2}        
        <div className="mv4" id="word-3">{wm1}</div>
      </div>
    );
    word_content.push(
      <div className="pa5 xgreen lh-copy h-100 overflow-scroll">
        {st1}
        {st2}        
        {st3}
      </div>
    )
  }


  showResults(e) {
    var $this = this;
    e.stopPropagation();
    $('#mask').addClass('show');
    setTimeout(function(){
      $this.setState({
        result: true
      });
      $('#mask').removeClass('show');
    }, 600);
  }

  showFaces(e) {
    window.location.reload();
  }
  componentDidMount(){
    $('body, html').addClass('hidden');
    var imageList = [f1, f2, f3, f4, f5, f6, e1, e2, e3, e4, n1, n2, n3, n4, m1, m2, m3, m4]
    imageList.forEach((image) => {
        new Image().src = image
    });

    var $this = this;
    var data = this.state.data;
    $('#eyes img').attr('src', data["eyes"][this.state.eyes-1]);
    $('#nose img').attr('src', data["nose"][this.state.nose-1]);
    $('#mouth img').attr('src', data["mouth"][this.state.mouth-1]);
    $('#mask').addClass('show');
    $('#word-1').addClass('hide');
    setTimeout(function(){
      $('#mask').removeClass('show');
      setTimeout(function(){
        $('#word-1').removeClass('hide');  
      }, 400)
    }, 200);
    
    $(window)
    .on('mousemove', function(event) {
        $('.searchlight').addClass('on').css({'margin-left': event.pageX - 150, 'margin-top': event.pageY - 150});
    })
    .on('mouseout', function(event) {
        $('.searchlight').removeClass('on');
    })

    $('#partE').hover(function(e){
      if(!$('body').hasClass('loading')){
        e.stopPropagation();
        $('.inactive').removeClass('inactive');
        $('#word-2, #word-3').addClass('inactive');
      }
    });
    $('#partN').hover(function(e){
      if(!$('body').hasClass('loading')){
        e.stopPropagation();
        if($(this).attr('data-tip') !== "") {
          $('.inactive').removeClass('inactive');
          $('#word-1, #word-3').addClass('inactive');
        }
      }
    });
    $('#partM').hover(function(e){
      if(!$('body').hasClass('loading')){
        e.stopPropagation();
        if($(this).attr('data-tip') !== "") {
          $('.inactive').removeClass('inactive');
          $('#word-1, #word-2').addClass('inactive');
        }
      }
    });
    $(window).hover(function(e){
      if(!$('body').hasClass('loading')){
        $('.inactive').removeClass('inactive');
      }
    })
    
    $('#partE').click(function(){
      if($('#eyes').hasClass('spot')) {
        $('#eyes').removeClass('spot');
        $('#nose, #partE').removeClass('hide');
        $(this).attr('data-tip', data["tooltips"][0][1]);
        setTimeout(function(){
          $this.setState({stage:2, change: false});
          $('body').addClass('loading');
          $('#word-2').addClass('hide');
          // $('#word-1').text(data["paragraphs1"][$this.state.eyes-1][0].concat(data["paragraphs1"][$this.state.eyes-1][1],data["paragraphs1"][$this.state.eyes-1][2],data["paragraphs1"][$this.state.eyes-1][3]));
          // $('#word-2').text(data["paragraphs2"][$this.state.nose-1][0].concat(data["paragraphs1"][$this.state.nose-1][1]));
          $('#partN').attr('data-tip', data["tooltips"][1][0]);
        }, 400)
      }
      else {
        if($this.state.stage === 2 ) {
          $this.changeContent(1);
          $this.setState({change: true});
        }
        if(!$('body').hasClass('loading')){
          var current = $this.state.eyes;
          if(current === data["eyes"].length) current = 1;
          else current++;
          
          if(!$('#eyes').hasClass('hide')) {
            $('#eyes').addClass('hide');
            $('#eyes1 img').attr('src', data["eyes"][current-1]);
            $('#eyes1').removeClass('hide');
            setTimeout(function(){
              $('#eyes img').attr('src', data["eyes"][current-1]);
              $this.setState({eyes:current});
            }, 400);
          } else {
            $('#eyes1').addClass('hide');
            $('#eyes img').attr('src', data["eyes"][current-1]);
            $('#eyes').removeClass('hide');
            setTimeout(function(){
              $('#eyes1 img').attr('src', data["eyes"][current-1]);
              $this.setState({eyes:current});
            }, 400);
          }

          $('#word-1').addClass('hide');
          setTimeout(function(){
            $('#word-1').text(data["paragraphs1"][current-1][0].concat(data["paragraphs1"][current-1][1],data["paragraphs1"][current-1][2],data["paragraphs1"][current-1][3]));
            $('#word-1').removeClass('hide');
          }, 400)
        }
      }
    })
    $('#partN').click(function(){
      if($('#nose').hasClass('hide') && $('#nose1').hasClass('hide'));
      else if($('#nose').hasClass('spot')) {
        $('#nose').removeClass('spot');
        $('#mouth, #partN').removeClass('hide');
        $(this).attr('data-tip', data["tooltips"][1][1]);
        
        setTimeout(function(){
          $this.setState({stage:3, change: false});
          $('body').addClass('loading');
          $('#word-3').addClass('hide');
          // $('#word-2').text(data["paragraphs2"][$this.state.nose-1][0].concat(data["paragraphs2"][$this.state.nose-1][1],data["paragraphs2"][$this.state.nose-1][2],data["paragraphs2"][$this.state.nose-1][3]));
          // $('#word-3').text(data["paragraphs3"][$this.state.mouth-1][0].concat(data["paragraphs3"][$this.state.mouth-1][1]));
          // $('#word-2').removeClass('hide');
          // setTimeout(function(){
          //   $('#word-3').removeClass('hide');
          // }, 400)
          $('#partM').attr('data-tip', data["tooltips"][2][0]);
        }, 400)
      }
      else {
        if($this.state.stage === 3 ) {
          $this.changeContent(2);
          $this.setState({change: true});
        }
        if(!$('body').hasClass('loading')){
          var current = $this.state.nose;
          if(current === data["nose"].length) current = 1;
          else current++;

          if(!$('#nose').hasClass('hide')) {
            $('#nose').addClass('hide');
            $('#nose1 img').attr('src', data["nose"][current-1]);
            $('#nose1').removeClass('hide');
            setTimeout(function(){
              $('#nose img').attr('src', data["nose"][current-1]);
              $this.setState({nose:current});
            }, 400);
          } else {
            $('#nose1').addClass('hide');
            $('#nose img').attr('src', data["nose"][current-1]);
            $('#nose').removeClass('hide');
            setTimeout(function(){
              $('#nose1 img').attr('src', data["nose"][current-1]);
              $this.setState({nose:current});
            }, 400);
          }

          $('#word-2').addClass('hide');
          setTimeout(function(){
            $('#word-2').text(data["paragraphs2"][current-1][0].concat(data["paragraphs2"][current-1][1],data["paragraphs2"][current-1][2],data["paragraphs2"][current-1][3]));
            $('#word-2').removeClass('hide');
          }, 400)
        }
      }
    })
    $('#partM').click(function(){
      if($('#mouth').hasClass('hide') && $('#mouth1').hasClass('hide'));
      else if($('#mouth').hasClass('spot')) {
        $('#mouth').removeClass('spot');
        $('#partM').removeClass('hide');
        $(this).attr('data-tip', data["tooltips"][2][1]);
        // $('#word-3').addClass('hide');
        setTimeout(function(){
          $this.setState({stage:4, change: false});
          $('body').addClass('loading');
          // $('#word-3').text(data["paragraphs3"][$this.state.mouth-1][0].concat(data["paragraphs3"][$this.state.mouth-1][1],data["paragraphs3"][$this.state.mouth-1][2],data["paragraphs3"][$this.state.mouth-1][3]));
          // $('#word-3').removeClass('hide');
        }, 400)
      }
      else {
        // $this.changeContent(3);
        // $this.setState({change: true});
        if(!$('body').hasClass('loading')){
          var current = $this.state.mouth;
          if(current === data["mouth"].length) current = 1;
          else current++;

          if(!$('#mouth').hasClass('hide')) {
            $('#mouth').addClass('hide');
            $('#mouth1 img').attr('src', data["mouth"][current-1]);
            $('#mouth1').removeClass('hide');
            setTimeout(function(){
              $('#mouth img').attr('src', data["mouth"][current-1]);
              $this.setState({mouth:current});
            }, 400);
          } else {
            $('#mouth1').addClass('hide');
            $('#mouth img').attr('src', data["mouth"][current-1]);
            $('#mouth').removeClass('hide');
            setTimeout(function(){
              $('#mouth1 img').attr('src', data["mouth"][current-1]);
              $this.setState({mouth:current});
            }, 400);
          }

          $('#word-3').addClass('hide');
          setTimeout(function(){
            $('#word-3').text(data["paragraphs3"][current-1][0].concat(data["paragraphs3"][current-1][1],data["paragraphs3"][current-1][2],data["paragraphs3"][current-1][3]));
            $('#word-3').removeClass('hide');
          }, 400)
        }
      }
    })
  }
  render() {
    var data = this.state.data;
    var result = !this.state.result ? "" : "result";
    var button = !this.state.result ? (
      <button id="result" className="dn" onClick={this.showResults}>{data["button"][0]}</button>
    ) : (
      <button id="back" className="restart" onClick={this.showFaces}>{data["button"][1]}</button>
    )
    var show = !this.state.result ? "o-0" : "o-100";

    let dataE = "";
    let dataN = "";
    let dataM = "";

    for(var i = 0; i < 6; i++) {
      dataE = dataE.concat(data["paragraphs1"][this.state.eyes-1][i]);
      dataN = dataN.concat(data["paragraphs2"][this.state.nose-1][i]);
    }
    for(var j = 0; j < 9; j++) {
      dataM = dataM.concat(data["paragraphs3"][this.state.mouth-1][j]);
    }
    if(!this.state.change) this.changeContent(this.state.stage - 2);

    var words = !this.state.result ? (
      word_content[this.state.stage-1]
    ) : (
      <div className="pa5 xgreen lh-copy h-100 overflow-scroll">
        <p className="mt4 mb6">{dataE}<br/><br/>{dataN}<br/><br/>{dataM}</p>
      </div>
    )

    var dn = !this.state.result ? "dn":"";
    var pb124 = this.state.result ? "":"pb124";

    var content = (
      <div className={"cf "+result}>
        <div className="fl w-50 relative h-100 bg-black items-center flex">
          <div className="relative mw-face center w-100">
            <div id="part1"></div>
            <div id="partE" className="hide" data-tip={data["tooltips"][0][0]}></div>
            <div id="partN" className="hide" data-tip=""></div>
            <div id="partM" className="hide" data-tip=""></div>
            <div id="part5"></div>
            <div id="face" className={"element absolute top-0 w-100 "+show}>
              <img src={data["face"][this.state.face-1]} width="100%" alt="face"/>
            </div>
            <div id="eyes" className="element spot absolute top-0 w-100">
              <img width="100%" alt="eyes"/>
            </div>
            <div id="eyes1" className="element hide absolute top-0 w-100">
              <img width="100%" alt="eyes"/>
            </div>
            <div id="nose" className="element hide spot absolute top-0 w-100">
              <img width="100%" alt="nose"/>
            </div>
            <div id="nose1" className="element hide absolute top-0 w-100">
              <img width="100%" alt="nose"/>
            </div>
            <div id="mouth" className="element hide spot absolute top-0 w-100">
              <img width="100%" alt="mouth"/>
            </div>
            <div id="mouth1" className="element hide absolute top-0 w-100">
              <img width="100%" alt="mouth"/>
            </div>
          </div>
        </div>
        <div className={"fl w-50 z-20 relative bg-dark h-100 "+pb124}>
          <div id="gradient-mask" className={dn + " absolute w-100 h-100 top-left"}></div>
          {words}
        </div>
      </div>
    )
    var spotlight = !this.state.result ? (
      <div className="searchlight"></div>
    ) : null;
    return (
      <section id="page-xdentity" className="flex items-center justify-center h-100 bg-light-gray">
      {/*
        <div className="lang white absolute">
          <span className="cp" onClick={() => this.changeLang()}>{this.state.lang === "en_US" ? "中":"EN"}</span>
        </div>
      */}
      <div id="xdentity" className="absolute pa3 bg-black white show">
        <div className="flex justify-between ttu">
            <Link to={"/"+window.location.hash} className="mh2 mv0 white navlink">{window.location.hash ? data_home[window.location.hash]: "Uchronia"}</Link>
            <span className="mh2 mv0 white navlink">{window.location.hash ? data_title[window.location.hash]: "Xdentity"}</span>
            <span className="about navlink cp">?</span>
        </div>
      </div>
        <div id="mask"></div>
        {/*}
        <nav className="absolute top-0 right-0 white z-99 pa4" id="HOMEBUTTON"><Link to={"/"+window.location.hash}>{this.state.data["home"]}</Link></nav>
        <h1>Uchronic <span className="stroke">Xdentity</span></h1>
      */}
        <div className="container overflow-hidden center w-100 h-100" id="face-container">
          {content}
        </div>
        {button}
        {spotlight}
        <ReactTooltip />
        <embed src={music} autostart="true" loop={true} width="2" height="0"></embed>
        <Modal/>
      </section>
    );
  }
}

export default Page2;

class Modal extends Component {
  constructor(props) {
        super(props);
        this.state = {
          lang: window.location.hash ? window.location.hash.replace('#',''): "en_US"
        }
    }
  componentDidMount() {
    $('.closeModal').click(function(){
      $('.modal').removeClass('show');
    })
    $('.about').click(function(){
      $('.modal').addClass('show');
    })
  }

  render() {  
  return(
    <div className="modal ph4 pv3">
      <h2 className="ttu f5 pb4">{window.location.hash ? data_about[window.location.hash]: data_about["en_US"]}</h2>
      <div className="closeModal cp"><img src={close} width="16px" height="16px"/></div>
      <p className="f6 fw2 lh-copy pre-wrap pb5">{window.location.hash ? data_about_content[window.location.hash]: data_about_content["en_US"]}</p>
    </div>
  )
  }
}


