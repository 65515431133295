//import ReactDOM from 'react-dom'
import React, { /*useRef, useState,*/ Component } from 'react'
import Cookies from 'universal-cookie';

//import { Canvas, useFrame } from 'react-three-fiber'
import { Link } from 'react-router-dom';
import $ from 'jquery';
import loadImage from 'image-promise';
import * as THREE from 'three';
import {OrbitControls} from "three/examples/jsm/controls/OrbitControls";
//import { CSS3DObject, CSS3DSprite, CSS3DRenderer } from "three/examples/jsm/renderers/CSS3DRenderer.js";

import TWEEN from '@tweenjs/tween.js';
import music from './images/FictiveNation.mp3';
// import effect from './images/Xdentity-Voice-3minutes.mp3';
import close from './images/close.svg';
import arrow from './images/arrow.svg';

const cookies = new Cookies();


var table1 = ["deVolksktrant", "Yomirui%20Shimbun%20(The%20Japan%20News)", "Lifemagazine", "People's%20Daily%2C%20Yule%2C%20XinHuaNet", "20%20Minutos%2C%20EL%20PA%C3%8DS", "TheJakartaPost"];
var table = []

var size = 12;
let zoom = false;
let level = 0.3;
let level2 = 0.2;
let loading = "0%";
let loadingP = 0;

//mouse
var mouse = new THREE.Vector2(),
    INTERSECTED2;
var mousedown = false;
var center = new THREE.Vector2(0,0), 
INTERSECTED;

//position
var targets = { grid: [], random: [], pick: [], split: []};
var pick = [];

//img
var imgMinList = [];
var imgMaxList = [];

// tags
var tag_count = [];
var tag_en = [
   "female",
"male",
"inside",
"outside",
"serious",
"smile",
"annoyed",
"angry",
"salute",
"upset",
"calm",
"content",
"absorbed",
"worried",
"alert",
"cheerful",
"vigorous",
"tired",
"work",
"talk",
"walk",
"takephoto",
"stand",
"watch",
"sitting",
"shakehands",
"pose",
"training",
"wave",
"gather",
"hold",
"deliveraspeech",
"demonstrating",
"perform",
"exercising",
"look",
"dance",
"meeting",
"fight",
"eat",
"rescuing",
"ride",
"headdown",
"hug",
"speaking",
"shooting",
"reading",
"mouthopen",
"shouting",
"protest",
"climb",
"show",
"lineup",
"clap",
"1890s",
"1900s",
"1910s",
"1920s",
"1930s",
"1940s",
"1950s",
"1960s",
"1970s",
"1980s",
"1990s",
"2000s",
"2010s",
"China_XinHuaNet_Yule_People's Daily",
"The Netherlands_deVolkskrant",
"Spain_El País_20 Minutos",
"USA_LIFEmagazine",
"Japan_Yomiuri Shimbun",
"Indonesia_The Jakarta Post",
"0person",
"1person",
"2people",
"3people",
"4people",
"5peopleandmore",
"backfacingcamera",
"facingawayfromcamera",
"facingcamera",
"extremelongshot",
"longshot",
"mediumshot",
"closeup"
];
var tag_zh = [
   "女性",
"男性",
"室內",
"室外",
"認真",
"笑",
"懊惱",
"生氣",
"致敬",
"難過",
"平靜",
"滿足",
"全神關注",
"擔憂",
"警戒",
"快活",
"積極",
"疲憊",
"工作",
"講話",
"走路",
"拍照",
"站立",
"看",
"坐著",
"握手",
"擺姿勢",
"訓練",
"揮手",
"聚集",
"抓住",
"演講",
"示範",
"表演",
"運動",
"注視",
"跳舞",
"會議",
"吵架",
"吃",
"拯救",
"騎",
"低頭",
"擁抱",
"說話",
"射擊",
"閱讀",
"張嘴",
"咆哮",
"抗議",
"爬",
"展示",
"排隊",
"拍手",
"1890s",
"1900s",
"1910s",
"1920s",
"1930s",
"1940s",
"1950s",
"1960s",
"1970s",
"1980s",
"1990s",
"2000s",
"2010s",
"中國_娛樂_新華網_人民日報",
"荷蘭_人民報",
"西班牙_20分鐘報_國家報",
"美國_生活雜誌",
"日本_讀賣新聞",
"印尼_雅加達郵報",
"零個人",
"一個人",
"兩個人",
"三個人",
"四個人",
"五個人以上包含五個人",
"主角背對鏡頭",
"主角沒看鏡頭",
"主角面對鏡頭",
"大遠景",
"遠景",
"中景",
"特寫"
];
var tag_data = {
  "en_US": tag_en,
  "zh_TW": tag_zh
}

var data_home = {
	"#en_US": "Uchronia",
	"#zh_TW": "Uchronia 烏有史"	
}
var data_title = {
	"#en_US": "Fictive Nation",
	"#zh_TW": "虛構國度"	
}

var data_about = {
	"#en_US": "About",
	"#zh_TW": "關於"	
}

var data_about_content = {
	"#en_US": "In the section Fictive Nation, we have compiled a conceptual database linking over two thousand newspaper clippings, forming a collage of images relating to “Taiwan,” published by historically influential media outlets owned by its former colonizers and other politically relevant countries. Traditional filters/tags in an archive, such as blood source, nationality create oppositions instead of showing  the complexity and hybridity of Taiwan.\n\nIn contrast, we develop two methods:\nFirst, the database includes alternative search filters such as gender, gesture, distance from camera, etc. In the future, we are going to add a function so that users can add their own tags as well. The second is the use of reverse filters. Generally speaking, users can click on one or more filters in order for the algorithm to display content they are interested in. The Fictitious Nation begins by clicking on one of many displayed pictures. The system will automatically highlight all other images that contain the same \"tags\" as this photo. The user can then play with these tags - by removing one or more of them, a larger and larger “island” of images is formed in the center of the users’ screen.\n\nTaken together, these images from different eras and countries help uncover deeper patterns in neo-colonial narratives and information, controlled by global media powers. It also serves to reveal how the concept of “Nation” is constructed through ideology and hegemonic powers. The interface invites participants to create their own filters and thus form new collective narratives about the concept of Nation, or rather to deconstruct the idea itself.",
	"#zh_TW": "虛構的國度(Fictive Nation)是一個概念性檔案庫，藉由蒐集世界各國、不同時代新聞裡的「臺灣影像」，來理解不同國家與時代的人們是如何閱讀、建立出臺灣這個島嶼的知識，並且由此挖掘出影像背後所隱含的意識形態、文化結構與政治關係。傳統的分類方式—像是根據血源、省籍、國籍等—反而是創造對立，且無法顯示出台灣族群的複雜性。因此在這個資料庫中，我們發展出兩種方法：\n\n第一是給予每張照片數個「標記」（tag），這些「標記」並非典型的年代、地點、國家，而是包括：性別、主角與攝影機的距離、人的姿勢、表情等根據照片本身的情境所作出的標記，未來也希望可以讓使用者自行增加標記。\n\n第二是使用反向的篩選器，一般而言，使用者根據自己有興趣的內容，點選一個或多個篩選器以獲得更精確的資訊。而這個虛構的國度則是從點選圖片開始，系統會自動收集所有和這張照片有完全相同「標記」的其他影像，使用者可以藉由「刪除」右側某些刪選器，讓更多影像聚集，在中心慢慢形成越來越大的「島」。\n\n虛構的國度並不是嚴謹的蒐集證據或者做出宣稱與論述的實證歷史研究，而是提供一種觀看的方式，一種後設地思考「歷史方法論」的起點。"
}

var data_onboarding = {
	"#en_US": "Cross out the tags to gather more photos.",
	"#zh_TW": "點選"
}

let tag_current = [];



class Page1 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            media: [],
            current: null,
            intersect: null,
            tags: [],
            loading: "0%",
            loaded: false,
            onboarding: true,
            pickcount: 0,
            tagData: window.location.hash ? tag_data[window.location.hash.replace('#','')]: tag_data["en_US"]

        };
        this.onDocumentMouseMove = this.onDocumentMouseMove.bind(this);
        this.onDocumentMouseDown = this.onDocumentMouseDown.bind(this);
    }
    componentWillMount() {
        // Fetching data recursively
        table = [];
        var temp = cookies.get('source');
        if(temp === undefined) temp = "111111"
        temp = temp.toString();
        console.log(temp);
        for(var iii = 0; iii < 6; iii++){
          if(temp[iii] === "1") table.push(table1[iii]);
        }
        console.log(table1)
        console.log(table)
        this.recursiveFetch("", 0);
    }

    // Fetching Airtable data
    recursiveFetch(offset, i) {
        fetch('https://api.airtable.com/v0/appN4uL4PaDCi6GwE/' + table[i] + '?api_key=keys2HwQUQqJG6UkT' + offset)
            .then((resp) => resp.json())
            .then(data => {
                this.setState({
                    media: this.state.media.concat(data.records),
                    loading: loadingP+"%"
                });
                console.log(this.state.media.length);
                loadingP++;
                var o = data.offset ? '&offset=' + data.offset : "";
                if (!data.offset) {
                    i++;
                    loadingP = Math.floor(this.state.media.length * 100 / 2553);
                    loading = loadingP + "%";
                    if (i === table.length) {
                    	size = Math.floor(Math.sqrt(this.state.media.length));
                        this.completeFetch();
                        return false;
                    }
                } 
                return this.recursiveFetch(o, i);
            }).catch(err => {
                // Error :(
            });
    }

    // Complete Fetch
    completeFetch() {
    	console.log("START");

    	// Reset
    	targets = { grid: [], random: [], pick: [], split: []};
		pick = [];
    	imgMinList = [];
    	imgMaxList = [];

    	for(var ig = 0; ig < this.state.media.length; ig++){
    		var media = this.state.media[ig];
    		var img = media.fields.Attachments[0].thumbnails ? media.fields.Attachments[0].thumbnails.small.url : media.fields.Attachments[0].url;
    		imgMinList.push(img);
    		imgMaxList.push(media.fields.Attachments[0].url);
    	}
    	console.log(imgMinList.length);

        const width = this.mount.clientWidth
        const height = this.mount.clientHeight

        //ADD SCENE
        this.scene = new THREE.Scene()
        //ADD CAMERA
        this.camera = new THREE.PerspectiveCamera(75, width / height, .1, 1000)
        this.camera.position.set(0, 0, 4);
        //
        this.raycaster = new THREE.Raycaster();
        this.raycaster2 = new THREE.Raycaster();


        //ADD RENDERER
        this.renderer = new THREE.WebGLRenderer({
            antialias: true
        })
        this.renderer.setClearColor('#ffffff')
        this.renderer.setSize(width, height)
        this.mount.appendChild(this.renderer.domElement)

        document.getElementById('canvas').addEventListener('mousemove', this.onDocumentMouseMove, false);
        document.getElementById('canvas').addEventListener('mousedown', this.onDocumentMouseDown, false);
        document.getElementById('canvas').addEventListener('mouseup', this.onDocumentMouseUp, false);

        // controls
        var c = this.camera;
        var $t = this;
        var tempV = new THREE.Vector3();
        this.controls = new OrbitControls(this.camera, this.renderer.domElement);
        this.controls.addEventListener( 'change', function(){
        	if(c.position.z <= level2+0.001) {
        		var tempI = $t.state.intersect;
        		if(tempI) {
        			$('#labels').removeClass('dn');
        			$( "div[numID='"+$t.state.current.numID+"']").removeClass('o-0');
        			var h = $t.state.current.source ? 82 : 65;
			        $('#labels, #labels > div').css({width: 717*$t.state.current.scale.x +"px"});
			        $('#labels').css({top: 717*$t.state.current.scale.y/2 - h +"px"});

	        		tempI.updateWorldMatrix(true, false);
			        tempI.getWorldPosition(tempV);
			        tempV.project($t.camera);
			        const x = (tempV.x *  .5 + .5) * $t.mount.clientWidth;
			        const y = (tempV.y * -.5 + .5) * $t.mount.clientHeight;
					const label = document.querySelector('#labels');
					label.style.transform = `translate(-50%, -50%) translate(${x}px,${y}px)`;
				} else {
					$('#labels').addClass('dn');
				}
	        } else {
        		$('#labels').addClass('dn');
        	}
        }); // call this only in static scenes (i.e., if there is no animation loop)
        this.controls.enableDamping = true; // an animation loop is required when either damping or auto-rotation are enabled
        this.controls.enableRotate = false;
        this.controls.dampingFactor = 0.1;
        this.controls.zoomSpeed = 2;
        this.controls.mouseButtons = {
            MIDDLE: THREE.MOUSE.DOLLY,
            LEFT: THREE.MOUSE.PAN
        }
        this.controls.update();

        this.controls.screenSpacePanning = true;
        this.controls.minDistance = .2;
        this.controls.maxDistance = 4;
        this.controls.maxPolarAngle = Math.PI / 2;

        this.group = new THREE.Group();
        this.groupC = new THREE.Group();

        // for(var i = 0; i < tag_en.length; i++){
        // 	tag_count[i] = 0;
        // }
        // console.log(tag_count);

        if (this.state.media !== null) {
        	console.log(this.state.media)
	        for (var i = 0; i < this.state.media.length; i++) {
	        	var tempC = this.createCard(i,[i % size / 4 - (size / 2 - 1) * 0.25, Math.floor(i / size) / 4 - (size / 2 - 1) * 0.25, 0], this.state.media[i]);
	        	// let css = null;
	        	// css = new THREE.Mesh(new THREE.PlaneGeometry(0.2*tempC.scale.x, 0.2*tempC.scale.y, 32), new THREE.MeshBasicMaterial( { color: 0x000000 } ));
	        	// css.position.set(tempC.position.x, tempC.position.y, tempC.position.z);
	        	// console.log(css);
	        	// css = new THREE.Mesh(new THREE.PlaneGeometry(0.2, 0.2, 32), new THREE.MeshBasicMaterial( { color: 0x000000 } ));
	            this.group.add(tempC);
	            // this.groupC.add(css);
	        }
	    }
	    // console.log(tag_count);

	    console.log("BEFORE");
	    this.setState({
            loading: "preparing images..."
        });
        $('.loading').addClass('o-80');
        $t.scene.add($t.group);
        $t.scene.add($t.groupC);
        $t.start();
        console.log(imgMinList.length);

	    loadImage(imgMinList)
	    .then(function (allImgs) {
	      console.log(allImgs.length, 'images loaded!', allImgs);
	      loadingP = 100;
	      loading = loadingP + "%";
          setTimeout(function() {
          	$('.loading').removeClass('o-80');
            $('.loading').addClass('hide');
            $('#fictive').addClass('show');
            $t.setState({
	            loaded: true
	        });
            setTimeout(function(){
            	loadingP = 0;
            },1000)
            console.log("Loading Complete");
            var audio = $('#music');
            audio[0].play();
            //console.log(targets);
          }, 1000);
	    })
	    .catch(function (err) {
	      console.error('One or more images have failed to load :(');
	      console.error(err.errored);
	      console.info('But these loaded fine:');
	      console.info(err.loaded);
	    }); 
        
    }

    onDocumentMouseMove = (event) => {
        event.preventDefault();
        if (!mousedown) {
            mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
            mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;
            // const label = document.querySelector('#labels');
            // label.style.left = event.clientX + "px";
            // label.style.top = event.clientY + "px";
        }
    }

    onDocumentMouseDown = (event) => {
        event.preventDefault();
        mousedown = true;
        mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
        mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;
    }

    onDocumentMouseUp = (event) => {
        event.preventDefault();
        var threshold = level
        var moved = Math.abs(mouse.x - ((event.clientX / window.innerWidth) * 2 - 1)) > threshold || Math.abs(mouse.y - (-(event.clientY / window.innerHeight) * 2 + 1)) > threshold;
        if (!moved) {
            //console.log("nomove");
            

            this.raycaster.setFromCamera(mouse, this.camera);

            var intersects = this.raycaster.intersectObjects(this.group.children);
            var $t = this;

            if (intersects.length > 0) {
            	console.log(intersects[0].object);
            	var same = false;
            	if(intersects[0].object.active) {
            		same = true;
            	}
                intersects[0].object.callback(true);

                if(!same) {
                	console.log("NOBACK?");
	                var x0 = this.group.position.x;
	                var y0 = this.group.position.y;
	                //console.log(x0+"-"+y0);
	                var position = { x : x0, y: y0 };
					var target = { x : -intersects[0].object.position.x+this.camera.position.x, y: -intersects[0].object.position.y+this.camera.position.y };
					if($t.camera.position.z > level) {
						var tween = new TWEEN.Tween(position)
							.to(target, 1000)
							.easing( TWEEN.Easing.Quartic.InOut )
							.onUpdate( function () {
								$t.group.position.x = position.x;
								$t.group.position.y = position.y;
							} )
							.start();
					}
					targets.split = [];
					pick = [];
					var circle_size = 0;
					for(var kk = 0; kk < $t.state.media.length; kk++){
						if($t.group.children[kk].pick) circle_size++;
					}

					$t.setState({pickcount:circle_size});
					var r = 8;
					if(circle_size <= 40 ) r = 1;
					else if(circle_size > 40 && circle_size <= 160) r = 2;
					else if(circle_size > 160 && circle_size <= 360) r = 3;
					else if(circle_size > 360 && circle_size <= 640) r = 4;
					else if(circle_size > 640 && circle_size <= 1000) r = 5;
					else r = 8;

			        for(var k = 0; k < $t.state.media.length; k++){
			            var object1 = new THREE.Object3D();
			            var object2 = new THREE.Object3D();
			            var object3 = new THREE.Object3D();
			            if($t.group.children[k].pick) {
			            	if($t.group.children[k] === intersects[0].object) {
			            		object1.position.z = 0;
								object1.position.x = intersects[0].object.position.x;
								object1.position.y = intersects[0].object.position.y;
								pick.push(object1.position.x+"-"+object1.position.y);
								targets.split.push( object1 );
			            	} else {
				            	var c = r + 1;
								while (c > r) {
									object2.position.x = -8+0.25*Math.floor(Math.random()*64);
									object2.position.y = -8+0.25*Math.floor(Math.random()*64);
									c = Math.sqrt( object2.position.x*object2.position.x + object2.position.y*object2.position.y );

									var a = object2.position.x + intersects[0].object.position.x;
									var b = object2.position.y + intersects[0].object.position.y;
									if(pick.includes(a+"-"+b)) c = r + 1;

								}
								object2.position.z = 0;
								object2.position.x += intersects[0].object.position.x;
								object2.position.y += intersects[0].object.position.y;
								pick.push(object2.position.x+"-"+object2.position.y);
								targets.split.push( object2 );
							}
			            } else {
							var d = 0;
							while (d < r + 1) {
								object3.position.x = -10+0.25*Math.floor(Math.random()*80);
								object3.position.y = -10+0.25*Math.floor(Math.random()*80);
								d = Math.sqrt( object3.position.x*object3.position.x + object3.position.y*object3.position.y );
								// var a = object3.position.x + intersects[0].object.position.x;
								// var b = object3.position.y + intersects[0].object.position.y;
								// if(pick.includes(a+"-"+b)) d = 0;
							}
							object3.position.z = 0;
							object3.position.x += intersects[0].object.position.x;
							object3.position.y += intersects[0].object.position.y;
							// pick.push(object3.position.x+"-"+object3.position.y);
							targets.split.push( object3 );
						}
						
					}
					
					setTimeout(function(){
						$t.transform( targets.split, 2000 );
						console.log("split_mouseup");
					}, 1000);
				}
            }
        } else {
            // console.log("move!");
        }
        mousedown = false;
        mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
        mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;
    }

    componentDidMount() {
    	var audio = $('#music');
        audio[0].pause();



        $('body, html').removeClass('hidden');
        $('.welcome-btn').click(function() {
            $('.welcome').addClass('hide');
        })

        console.log("MOUNT");
        var $t = this;
        var buttonR = document.getElementById( 'random' );
		buttonR.addEventListener( 'click', function () {
			$t.transform( targets.random, 2000 );
		}, false );
		var buttonG = document.getElementById( 'grid' );
		buttonG.addEventListener( 'click', function () {
			$t.transform( targets.grid, 2000 );
		}, false );
		var buttonC = document.getElementById( 'circle' );
		buttonC.addEventListener( 'click', function () {
			$t.transform( targets.pick, 2000 );
		}, false );
		var buttonS = document.getElementById( 'split' );
		buttonS.addEventListener( 'click', function () {
			$t.transform( targets.split, 2000 );
		}, false );
    }

    tagClick = (e) => {

    	var $et = $(e.target);
    	var $t = this;

    	if($('#onboarding').hasClass('show')){
    		$('#onboarding').removeClass('show');
        $('#onboarding').addClass('pn');
    		$t.setState({onboarding: false})
    	}

    	if($et.hasClass('active')) {
    		$et.toggleClass('off');
	    	var index = tag_current.indexOf($et.data("name"));
	    	if (index !== -1) tag_current.splice(index, 1);
			else tag_current.push($et.data("name"));
			$t.state.current.callback(false);

			
			var circle_size = 0;
			for(var kk = 0; kk < $t.state.media.length; kk++){
				if($t.group.children[kk].pick) circle_size++;
			}

			if(circle_size !== $t.state.pickcount) {

				targets.split = [];
				pick = [];
				$t.setState({pickcount:circle_size});
				var r = 8;
				if(circle_size <= 40 ) r = 1;
				else if(circle_size > 40 && circle_size <= 160) r = 2;
				else if(circle_size > 160 && circle_size <= 360) r = 3;
				else if(circle_size > 360 && circle_size <= 640) r = 4;
				else if(circle_size > 640 && circle_size <= 1000) r = 5;
				else r = 8;
				
		        for(var k = 0; k < $t.state.media.length; k++){
		            var object1 = new THREE.Object3D();
		            var object2 = new THREE.Object3D();
		            var object3 = new THREE.Object3D();
		            if($t.group.children[k].pick) {
		            	if($t.group.children[k] === $t.state.current) {
		            		object1.position.z = 0;
							object1.position.x = $t.state.current.position.x;
							object1.position.y = $t.state.current.position.y;
							pick.push(object1.position.x+"-"+object1.position.y);
							targets.split.push( object1 );
		            	} else {
			            	var c = r + 1;
							while (c > r) {
								object2.position.x = -8+0.25*Math.floor(Math.random()*64);
								object2.position.y = -8+0.25*Math.floor(Math.random()*64);
								c = Math.sqrt( object2.position.x*object2.position.x + object2.position.y*object2.position.y );

								var a = object2.position.x + $t.state.current.position.x;
								var b = object2.position.y + $t.state.current.position.y;
								if(pick.includes(a+"-"+b)) c = r + 1;

							}
							object2.position.z = 0;
							object2.position.x += $t.state.current.position.x;
							object2.position.y += $t.state.current.position.y;
							pick.push(object2.position.x+"-"+object2.position.y);
							targets.split.push( object2 );
						}
		            } else {
						var d = 0;
						while (d < r + 1) {
							object3.position.x = -10+0.25*Math.floor(Math.random()*80);
							object3.position.y = -10+0.25*Math.floor(Math.random()*80);
							d = Math.sqrt( object3.position.x*object3.position.x + object3.position.y*object3.position.y );
							// var a = object3.position.x + intersects[0].object.position.x;
							// var b = object3.position.y + intersects[0].object.position.y;
							// if(pick.includes(a+"-"+b)) d = 0;
						}
						object3.position.z = 0;
						object3.position.x += $t.state.current.position.x;
						object3.position.y += $t.state.current.position.y;
						// pick.push(object3.position.x+"-"+object3.position.y);
						targets.split.push( object3 );
					}
					
				}
				// var audio = $('#effect');
	   //          audio[0].currentTime = 0;
	   //          audio[0].play();
	   //          setTimeout(function(){
	   //          	audio[0].pause();
	   //          }, 3000)

				$t.transform( targets.split, 2000 );
				console.log("split_tag");
				console.log(tag_current);
			}
		}
    }

    createCard = (id, position, media) => {
        var $t = this;
        var img = media.fields.Attachments[0].thumbnails ? media.fields.Attachments[0].thumbnails.small.url : media.fields.Attachments[0].url;
        
        //ADD CUBE
        const geometry = new THREE.PlaneGeometry(0.2, 0.2, 32);

        let mesh = null
        var texture = new THREE.TextureLoader().load(img, (tex) => {
            tex.needsUpdate = true;
            tex.image.height > tex.image.width ? mesh.scale.set(tex.image.width / tex.image.height, 1.0, 1.0) : mesh.scale.set(1.0, tex.image.height / tex.image.width, 1.0);
        });
        texture.minFilter = THREE.LinearFilter;
        var material = new THREE.MeshBasicMaterial({
            map: texture
        });
        mesh = new THREE.Mesh(geometry, material)
        mesh.material.transparent = true;

        mesh.position.set(position[0], position[1], position[2]);

        // keep position
        var object1 = new THREE.Object3D();
		object1.position.x = position[0];
		object1.position.y = position[1];
		object1.position.z = position[2];
		targets.grid.push( object1 );


        mesh.imgMax = media.fields.Attachments[0].url;
        mesh.imgMin = img;
        mesh.tags = Array.from(media.fields.tag);

        // for(var ii = 0; ii < mesh.tags.length; ii++) {
        // 	var temp = mesh.tags[ii];
        // 	var index = tag_en.indexOf(temp);
        // 	if(index >= 0) tag_count[index]++;
        // }


        mesh.active = false;
        mesh.info = media.fields.Citation.indexOf('http') >=0 ? media.fields.Citation.split('http')[0].split(', URL')[0]:media.fields.Citation
        mesh.source = media.fields.Citation.indexOf('http') >=0 ? "http"+media.fields.Citation.split('http')[1] : null;

		const label = document.querySelector('#labels');
        const elemd = document.createElement('div');
        elemd.classList.add('pa3', 'bg-black', 'f7', 'w5', 'o-0');
        const elem = document.createElement('p');
        const elema = document.createElement('a');

        mesh.numID = id;
        elemd.setAttribute('numID', id);
        elema.setAttribute('href', mesh.source);
        elema.setAttribute('target', "_blank");

		elem.textContent = mesh.info;
		elema.textContent = "source";
		elemd.appendChild(elem);
		label.appendChild(elemd);
		if(mesh.source !== null) elemd.appendChild(elema);
		

        mesh.callback = function(b) {
        	// var audio = $('#effect');
            // audio[0].currentTime = 0;
            setTimeout(function(){
            	if($t.state.onboarding) {
                $('#onboarding').addClass('show');
                $('#onboarding').removeClass('pn');
              }
            	// audio[0].play();
            	// setTimeout(function(){
	            // 	audio[0].pause();
	            // }, 3000)
            }, 1000);
        	if(b) {
        		mesh.tags = Array.from(media.fields.tag);
	        	//console.log(mesh.tags);
	        	tag_current = Array.from($t.state.tags);
	        	$t.setState({tags: mesh.tags, current:mesh});
	        	for (var k = 0; k < $t.group.children.length; k++) {
	                $t.group.children[k].material.opacity = 1;
	                $t.group.children[k].pick = true;
	            }
	            if (mesh.active) {
	                mesh.active = false;
	                $('.tagList').removeClass('show');
	                // mesh.children = [];
	                $('.tagList li').removeClass('active');
	                $('.tagList li').removeClass('off');
	                $('.tagList li').removeClass('o-10');
	                tag_current = [];
	                $t.transform( targets.grid, 2000 );

	            } else {
	            	setTimeout(function(){
	            		$('.tagList').addClass('show');
	            	}, 1000)
	            	tag_current = mesh.tags;
	                for (var i = 0; i < $t.group.children.length; i++) {
	                	let result = true;
	                	let tagOther = null;
	                    if (mesh !== $t.group.children[i]) {
	                    	tagOther = $t.group.children[i].tags;
	                        $t.group.children[i].children = [];
	                        $t.group.children[i].active = false;
	                        result = tag_current.every(function(val) { 
				                return tagOther.indexOf(val) >= 0; 
				            }); 
	                        //tag_current.some(item => tagOther.includes(item)) 
	                        if(!result) {
	                        	$t.group.children[i].material.opacity = .2;
	                        	$t.group.children[i].pick = false;
	                        }
	                    }
	                }
	                mesh.active = true;
	                
	                mesh.material.opacity = 1;
	                mesh.pick = true;
	                var img = new Image();
					// 'load' event
					$(img).on('load', function() {
					  	mesh.material.map = new THREE.TextureLoader().load(mesh.imgMax);
	                	mesh.material.map.minFilter = THREE.LinearFilter;
					});
					img.src = mesh.imgMax;

	                $('.tagList li').removeClass('active');
	                $('.tagList li').removeClass('off');
	                $('.tagList li').removeClass('o-10');
	                $('.tagList li').each(function() {
	                    if (mesh.tags.includes($(this).data("name"))) $(this).addClass('active');
	                    else $(this).addClass('o-10');
	                });
	            }
	        } else {
	        	//console.log("TAG_False");
	        	for (var j = 0; j < $t.group.children.length; j++) {
	        		let result_ = true;
	                let tagOther_ = null;

	        		$t.group.children[j].material.opacity = 1;
	        		$t.group.children[j].pick = true;

                    if (mesh !== $t.group.children[j]) {
                    	//console.log("TAG???");
                    	tagOther_ = $t.group.children[j].tags;
                        $t.group.children[j].children = [];
                        $t.group.children[j].active = false;
                        result_ = tag_current.every(function(val) { 
				                return tagOther_.indexOf(val) >= 0; 
				            }); 
                        if(!result_) {
                        	//console.log("TAGResult");
                        	$t.group.children[j].material.opacity = .2;
                        	$t.group.children[j].pick = false;
                        }
                    }
                }
                //console.log($t.group.children);
	        }
        }
        return mesh
    }

    componentWillUnmount() {
    	if(this.state.loaded) {
    		this.stop()
        	this.mount.removeChild(this.renderer.domElement)
        }
    }

    start = () => {
        if (!this.frameId) {
            this.frameId = requestAnimationFrame(this.animate)
        }
    }
    stop = () => {
        cancelAnimationFrame(this.frameId)
    }
    animate = ( time ) => {
        // for(var i = 0; i < 50; i++){
        // this.group.children[i].rotation.x += 0.01
        // this.group.children[i].rotation.y += 0.01   
        // }
        this.controls.update();
        this.renderScene()
        this.frameId = window.requestAnimationFrame(this.animate)
        TWEEN.update( time );
    }

    transform( targets, duration ) {
    	var $t = this;
		TWEEN.removeAll();

		for ( var i = 0; i < $t.state.media.length; i ++ ) {

			var object = $t.group.children[i]
			var target = targets[i];

			new TWEEN.Tween( object.position )
				.to( { x: target.position.x, y: target.position.y, z: target.position.z }, Math.random() * duration + duration )
				.easing( TWEEN.Easing.Exponential.InOut )
				.start();
		}

		new TWEEN.Tween( this )
			.to( {}, duration * 2 )
			// .onUpdate( this.renderScene() )
			.start();
	}



    renderScene = () => {
    	//const tempV = new THREE.Vector3();
    	var $t = this;

    	if($t.state.loaded) {

	    	this.raycaster2.setFromCamera(mouse, this.camera);
	        var intersects2 = this.raycaster2.intersectObjects(this.group.children);
	        const geometry2 = new THREE.PlaneGeometry(0.2, 0.2, 32);
	        let edges = new THREE.EdgesGeometry(geometry2);
	        let border = new THREE.LineBasicMaterial({
	            color: "blue",
	            linewidth: 20
	        });
	        let line = new THREE.LineSegments(edges, border);


	        if (!mousedown) {
	            if (intersects2.length > 0) {

	                if (INTERSECTED2 !== intersects2[0].object) {

	                    if (INTERSECTED2) {
	                        
	                    }

	                    INTERSECTED2 = intersects2[0].object;
	                    for (var i = 0; i < this.group.children.length; i++) {
	                        if (!this.group.children[i].active) this.group.children[i].children = []
	                    }
	                    INTERSECTED2.add(line);
	                }

	            } else {
	                if (INTERSECTED2) {
	                    if (!INTERSECTED2.active) {
	                    	INTERSECTED2.children = [];
	                    }
	                }
	                INTERSECTED2 = null;
	            }
	        }

	        this.raycaster.setFromCamera(center, this.camera);
	        var intersects = this.raycaster.intersectObjects(this.group.children);

	        if (!mousedown) {
	            if (intersects.length > 0) {
	                if (INTERSECTED !== intersects[0].object) {
	                    if (INTERSECTED) {
	                        if (this.camera.position.z <= level) {
	                            INTERSECTED.material.map = new THREE.TextureLoader().load(INTERSECTED.imgMin);
								INTERSECTED.material.map.minFilter = THREE.LinearFilter;
	                        }
	                    }

	                    INTERSECTED = intersects[0].object;
	                    $t.setState({intersect: INTERSECTED, current: INTERSECTED});
	                    
	                    if (this.camera.position.z <= level) {
	                    	
	                    	var img = new Image();
							// 'load' event
							$(img).on('load', function() {
							  	INTERSECTED.material.map = new THREE.TextureLoader().load(INTERSECTED.imgMax);
	                    		INTERSECTED.material.map.minFilter = THREE.LinearFilter;
							});
							img.src = INTERSECTED.imgMax;

	                    	// INTERSECTED.position.z = 0.25;
	                    	$( "div[numID='"+INTERSECTED.numID+"']").removeClass('o-0');
	                    //console.log(INTERSECTED);
	                      //INTERSECTED.updateWorldMatrix(true, false);
					      //INTERSECTED.getWorldPosition(tempV);

					      // get the normalized screen coordinate of that position
					      // x and y will be in the -1 to +1 range with x = -1 being
					      // on the left and y = -1 being on the bottom
					      //tempV.project(this.camera);

					      // convert the normalized position to CSS coordinates
					      // const x = (tempV.x *  .5 + .5) * this.mount.clientWidth;
					      // const y = (tempV.y * -.5 + .5) * this.mount.clientHeight;

					      const label = document.querySelector('#labels');
					      // move the elem to that position
					      // label.style.transform = `translate(-50%, -50%) translate(${x}px,${y}px)`;
					      var h = INTERSECTED.source ? 82 : 65;
					      $('#labels, #labels > div').css({width: 717*INTERSECTED.scale.x +"px"});
					      $('#labels').css({top: 717*INTERSECTED.scale.y/2 - h +"px"});
					  }
	                }

	            } else {
	                if (INTERSECTED) {
	                    if (this.camera.position.z <= level)
	                        INTERSECTED.material.map = new THREE.TextureLoader().load(INTERSECTED.imgMin);
	                    	// INTERSECTED.position.z = 0;
	                    INTERSECTED.material.map.minFilter = THREE.LinearFilter;
	                    if (!INTERSECTED.active) {
	                    	$("#labels div").addClass('o-0');
	                    }
	                }
	                INTERSECTED = null;
	                $t.setState({intersect: null});

	            }
	        }
	    }
        this.renderer.render(this.scene, this.camera)
    }

  reset = () => {
    if(!$('#onboarding').hasClass('show')){
      for (var k = 0; k < this.group.children.length; k++) {
          this.group.children[k].material.opacity = 1;
          this.group.children[k].pick = true;
      }
      this.state.current.active = false;
      $('.tagList').removeClass('show');
      $('.tagList li').removeClass('active');
      $('.tagList li').removeClass('off');
      $('.tagList li').removeClass('o-10');
      tag_current = [];
      this.transform( targets.grid, 2000 );
    }
  }

  render() {
  	const listItems = tag_en.map((tag, index) => <li data-name={tag.toString()} onClick={this.tagClick} key={tag.toString()}>{this.state.tagData[index]/*+'='+tag_count[index]*/}</li>);
    return (
      <section id="page-fictive-nation" className="pa0">
      	<div className="absolute loading top-0 left-0 w-100 h-100 bg-white black z-20"><p>Loading...{this.state.loading}</p></div>
      	<div id="onboarding" className="tr pn">
      		<p className="di">{window.location.hash ? data_onboarding[window.location.hash]: data_onboarding["en_US"]}</p>
      		<img className="di" src={arrow} width="20px"/>
      	</div>

      {/*<div className="welcome">
      	<div className="welcome-btn">Enter Fictive Nation</div>
      </div>*/}

      <div id="fictive" className="absolute pa3 bg-black white">
      	<div className="flex justify-between ttu">
	      	  <Link to={"/"+window.location.hash} className="mh2 mv0 white navlink">{window.location.hash ? data_home[window.location.hash]: "Uchronia"}</Link>
	      	  <span className="mh2 mv0 white navlink" onClick={this.reset}>{window.location.hash ? data_title[window.location.hash]: "Fictive Nation"}</span>
	      	  <span className="about navlink cp">?</span>
		</div>
	      <div className="dn">
		      <div id="random">Random</div>
		      <div id="grid">Grid</div>
		      <div id="circle">Circle</div>
		      <div id="split">Split</div>
		  </div>
	  </div>
	      {/*this.state.media ? <div className="flex mt4 overflow-scroll">{this.state.media.map(media => <Tag {...media.fields}/>)}</div>:null*/}
      <div id="canvas"
        style={{ width: '100%', height: '100%' }}
        ref={(mount) => { this.mount = mount }}
      />
      <ul className="tagList">
      	{listItems}
      </ul>
      <div id="labels" className="dn"></div>
      <audio id="music" className="dn" src={music} preload="auto"></audio>
      <Modal/>
      </section>
    );
  }
}
/*
const Tag = ({ Citation, Attachments }) => (
  <div className="flex-w360 mh2">
  	{Attachments[0].thumbnails ? ( //thumbnails.small.url
  		<img src={Attachments[0].url} width="100%" alt={Citation}/>
  		): null
  	}
  	{Citation ? (
	  	<div className="pa3 bg-black f7">
		    <p className="white ma0 pa0">
		    	{Citation.indexOf('http') >=0 ? Citation.split('http')[0].split(', URL')[0]:Citation}
		    </p>
		    {Citation.indexOf('http') >=0 ? <a href={"http"+Citation.split('http')[1]} target="_blank" rel="noopener noreferrer" className="source underline"> source</a> : null}
		</div>
	):null}
  </div>
);
*/

export default Page1;

class Modal extends Component {
	constructor(props) {
        super(props);
        this.state = {
        	lang: window.location.hash ? window.location.hash.replace('#',''): "en_US"
        }
    }
	componentDidMount() {
		$('.closeModal').click(function(){
			$('.modal').removeClass('show');
		})
		$('.about').click(function(){
			$('.modal').addClass('show');
		})
	}

	render() {	
	return(
		<div className="modal ph4 pv3">
			<h2 className="ttu f5 pb4">{window.location.hash ? data_about[window.location.hash]: data_about["en_US"]}</h2>
			<div className="closeModal cp"><img src={close} width="16px" height="16px"/></div>
			<p className="f6 fw2 lh-copy pre-wrap pb5">{window.location.hash ? data_about_content[window.location.hash]: data_about_content["en_US"]}</p>
		</div>
	)
	}
}



