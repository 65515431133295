import React, { Component } from 'react';
import { Link } from 'react-router-dom';
// import rd3 from 'react-d3-library'

class About extends Component {
  render() {
    return (
      <section id="About">
        About
        <Link to="/">Home</Link>
      </section>
    );
  }
}

export default About;
